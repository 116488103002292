import { PublicClientApplication } from '@azure/msal-browser-1p';

export default async function createMsalPublicClientApplication(
    authUrl: string,
    clientId: string,
    redirectUri: string
): Promise<PublicClientApplication> {
    const msalConfig = {
        auth: {
            clientId,
            authority: authUrl,
            redirectUri,
            navigateToLoginRequestUrl: true,
        },
        cache: {
            cacheLocation: 'localStorage',
            storeAuthStateInCookie: false,
        },
        system: {
            loadFrameTimeout: 6000,
        },
    };
    const msalInstance = new PublicClientApplication(msalConfig);
    await msalInstance.initialize();

    return msalInstance;
}
