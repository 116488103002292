import type MsalTokenCallback from './MsalTokenCallback';
import type {
    AccountInfo,
    PopupRequest,
    PublicClientApplication,
    SilentRequest,
} from '@azure/msal-browser-1p';
import { InteractionRequiredAuthError } from '@azure/msal-browser-1p';
import { getScopeMsal } from './getScopeMsal';

export default function acquireTokenMsal(
    msalInstance: PublicClientApplication,
    resource: string,
    authChallenge: string,
    allowConsentPrompt: boolean,
    userAccount: AccountInfo,
    getAccessTokenCallback: MsalTokenCallback
): void {
    const scopes = [getScopeMsal(resource)];
    const tokenRequest: SilentRequest | PopupRequest = {
        scopes,
        account: userAccount,
        claims: authChallenge,
    };

    msalInstance
        .acquireTokenSilent(tokenRequest)
        .catch(function (error) {
            if (allowConsentPrompt && error instanceof InteractionRequiredAuthError) {
                return msalInstance.acquireTokenPopup(tokenRequest);
            }

            return Promise.reject(error);
        })
        .then(function (tokenResponse) {
            const accessToken = tokenResponse.accessToken;
            // Success. Using callback to return token.
            getAccessTokenCallback(null, accessToken);
        })
        .catch(function (error) {
            // Error. Using callback to return error.
            getAccessTokenCallback(error, null /* token */);
        });
}
