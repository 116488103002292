import type { AuthError, AuthenticationResult } from '@azure/msal-common';
import type { InteractionType } from './InteractionType';
import type { AccountSourceType } from 'owa-account-source-list-types';

export function getLogAuthResultData(
    interactionType: InteractionType,
    authResult: AuthenticationResult,
    authRequestStartTime: number,
    typeHint?: AccountSourceType,
    isUrlPresent?: boolean,
    isPageVisible?: boolean,
    requestedScope?: string
) {
    return {
        interactionType,
        isSuccess: authResult.accessToken.length > 0 ? true : false,
        latency: self.performance.now() - authRequestStartTime,
        accountType: typeHint,
        isValidAuthorityUrl: isUrlPresent,
        correlationId: authResult.correlationId,
        scopes: authResult.scopes.join(' '),
        fromCache: authResult.fromCache,
        pageVisible: isPageVisible,
        requestedScope,
    };
}

export function getLogAuthErrorData(
    interactionType: InteractionType,
    e: AuthError,
    authRequestStartTime: number,
    scopes?: string[],
    typeHint?: AccountSourceType,
    isUrlPresent?: boolean,
    isCAERemediationRequired?: boolean,
    isPageVisible?: boolean
) {
    return {
        interactionType,
        isSuccess: false,
        latency: self.performance.now() - authRequestStartTime,
        accountType: typeHint,
        scopes: scopes?.join(' '),
        correlationId: e.correlationId,
        errorCode: e.errorCode,
        errorMessage: e.errorMessage,
        subError: e.subError,
        isValidAuthorityUrl: isUrlPresent,
        isCAERemediationRequired,
        pageVisible: isPageVisible,
    };
}
