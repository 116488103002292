import type { AccountInfo, IPublicClientApplication } from '@azure/msal-browser-1p';
import { type MailboxInfo, getIndexerValueForMailboxInfo } from 'owa-client-types';
import { getAccountScopeUserSettings } from 'owa-session-store/lib/selectors/getAccountScopeUserSettings';
import { setIsAuthenticated } from '../isAnonymousSession';
import { setOwaAppId } from 'owa-config/lib/getOwaAppId';
import { logStartUsage } from 'owa-analytics-start';
import type { CustomDataMap } from 'owa-analytics-types';
import { getLoginHint } from './getLoginHint';

const mailboxInfoIndexerValue_to_homeAccountId = new Map<string, string>();

export function getAccountFromMsal(
    msalInstance: IPublicClientApplication,
    mailboxInfo: MailboxInfo
): AccountInfo | null {
    let account: AccountInfo | null = null;
    const diagnosticData: CustomDataMap = {};

    const key = getIndexerValueForMailboxInfo(mailboxInfo);
    let homeAccountId = mailboxInfoIndexerValue_to_homeAccountId.get(key);
    diagnosticData.hasMailboxInfoIndexerValue = !!key;
    diagnosticData.homeAccountIdFoundInCache = !!homeAccountId;

    if (!homeAccountId) {
        const sessionData = getAccountScopeUserSettings(mailboxInfo).SessionSettings;

        if (sessionData?.ExternalDirectoryUserGuid && sessionData.ExternalDirectoryTenantGuid) {
            homeAccountId = `${sessionData.ExternalDirectoryUserGuid}.${sessionData.ExternalDirectoryTenantGuid}`;
        }

        diagnosticData.hasExternalDirectoryUserGuid = !!sessionData?.ExternalDirectoryUserGuid;
        diagnosticData.hasExternalDirectoryTenantGuid = !!sessionData?.ExternalDirectoryTenantGuid;
    }
    account = msalInstance.getAccount({ homeAccountId });
    diagnosticData.getAccountByHomeAccountIdResult = account ? 'Success' : 'Failure';

    if (!account) {
        const loginHint = getLoginHint(undefined /*msalAccount*/, mailboxInfo);
        account = msalInstance.getAccount({ loginHint });

        diagnosticData.hasLoginHint = !!loginHint;
        diagnosticData.getAccountByLoginHintResult = account ? 'Success' : 'Failure';
    }

    if (key && account) {
        mailboxInfoIndexerValue_to_homeAccountId.set(key, account.homeAccountId);

        diagnosticData.accountUsernameMatchesMailboxUserIdentity =
            account?.username === mailboxInfo.userIdentity;
    }

    diagnosticData.success = !!account;
    logStartUsage('Msal-GetAccountFromMsal', diagnosticData);

    return account;
}

export function getAccountFromMsalByUsername(
    msalInstance: IPublicClientApplication,
    username: string
): AccountInfo | null {
    return msalInstance.getAccount({ username });
}

export function getAllAccountFromMsal(msalInstance: IPublicClientApplication): AccountInfo[] {
    return msalInstance.getAllAccounts();
}

export function getActiveAccountFromMsal(
    msalInstance: IPublicClientApplication
): AccountInfo | null {
    return msalInstance.getActiveAccount();
}

export function setActiveAccountFromMsal(
    msalInstance: IPublicClientApplication,
    account: AccountInfo
): void {
    msalInstance.setActiveAccount(account);
}

export function setActiveAccountRelatedProperties(
    msalInstance: IPublicClientApplication,
    account: AccountInfo
): void {
    // store the app id from msal config in local token storage. This will be used to sync the app id between the client and server.
    setOwaAppId(msalInstance.getConfiguration().auth.clientId);
    setActiveAccountFromMsal(msalInstance, account);
    // most apps that rely on Anonymous sessions like FindTime, Bookings & BookWithMe
    // need this to determine whether a session is authenticated or not.
    setIsAuthenticated();
}

export function setMailboxInfoToAccountMapping(
    mailboxInfo: MailboxInfo,
    account: AccountInfo
): void {
    const key = getIndexerValueForMailboxInfo(mailboxInfo);
    if (key) {
        mailboxInfoIndexerValue_to_homeAccountId.set(key, account.homeAccountId);
    }
}
