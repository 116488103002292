import type { MailboxInfo } from 'owa-client-types';
import type { HeadersWithoutIterator } from 'owa-service/lib/RequestOptions';
import { getQueryStringParameters } from 'owa-querystring';
import {
    getAccountFromMsalByUsername,
    getActiveAccountFromMsal,
    getAllAccountFromMsal,
    setActiveAccountRelatedProperties,
} from './utils/MsalAccounts';
import type {
    AccountInfo,
    AuthError,
    AuthenticationResult,
    IPublicClientApplication,
} from '@azure/msal-browser-1p';
import {
    BrowserAuthError,
    BrowserAuthErrorCodes,
    InteractionRequiredAuthError,
    PromptValue,
} from '@azure/msal-browser-1p';
import { acquireAccessTokenMsal, acquireTokenSilently } from './acquireAccessTokenMsal';
import type { AccountSourceType } from 'owa-account-source-list-types';
import { handleLoginRedirectPromise, loginUserMsalInternal } from './loginUserMsal';
import { InteractionType } from './utils/InteractionType';
import { getMsalInstance } from './initializeMsalLibrary';
import { getAnchorMailboxFromMsalAccount } from './getAnchorMailbox';
import { getTypeHintFromAccountInfo, getTypeHintFromDomain } from './getTypeHint';
import { isMsalEnabledForBusiness } from './isMsalEnabledForBusiness';
import { isMonitoringProbe } from './isMonitoringProbe';
import { logStartCoreUsage } from 'owa-analytics-start';
import getModuleContextMailboxInfo from 'owa-module-context-mailboxinfo/lib/selectors/getModuleContextMailboxInfo';
import isAccountSourceListStoreInitialized from 'owa-account-source-list-store/lib/utils/isAccountSourceListStoreInitialized';
import { isHosted } from 'owa-config';
import { msalAuthenticationResultToAuthorizationHeader } from './utils/msalAuthenticationResultToTokenResponse';
import getClaims from './utils/getClaims';
import { isMsalEnabledForConsumerDomain } from './isMsalEnabledForConsumerDomain';
import { getGuid } from 'owa-guid';
import { setAuthTiming } from 'owa-auth-timings';
import { isMsalEnabledForConsumer } from './isMsalEnabledForConsumer';
import { getIsSigninRequired } from './utils/signinRequiredHelpers';
import { setupBackgroundTokenRefresh } from './setupBackgroundTokenRefresh';

// Login request during boot only needs to be processed once, cache the promise to avoid duplicate handling.
let loginPromise: Promise<string | undefined> | undefined;
export function resetLoginPromise(): void {
    loginPromise = undefined;
}

export async function getAuthTokenMsal(
    headers?: HeadersWithoutIterator,
    mailboxInfo?: MailboxInfo
): Promise<string | undefined> {
    if (!mailboxInfo && isAccountSourceListStoreInitialized()) {
        mailboxInfo = getModuleContextMailboxInfo();
    }
    const correlationId = getGuid();

    const promptValue = getPromptValue();
    const loginHint = getLoginHint();

    // If mailboxInfo is available, it means user has already logged in and sessions data has been fetched, then it should acquire token silently.
    // If prompt or loginhint is present in the url, then OWA should always prompt user to the login page or fetch MSAL account using loginhint.
    if (!loginHint && !promptValue && mailboxInfo) {
        setupBackgroundTokenRefresh(mailboxInfo);

        if (getIsSigninRequired()) {
            return undefined;
        }

        resetLoginPromise(); // Since user has already logged in, loginPromise shouldn't be needed anymore, reset the value to prevent unexpected access.
        const tokenResponse = await acquireAccessTokenMsal(
            mailboxInfo,
            undefined /*resource*/,
            undefined /*scope*/,
            headers,
            correlationId
        );
        return tokenResponse.AccessToken;
    }

    const claims = headers && getClaims(headers);
    // Cache the login promise to avoid duplicate handling.
    // Always get a new token if claims are present.
    if (!loginPromise || claims) {
        loginPromise = getAuthTokenForStartupData(
            correlationId,
            loginHint,
            claims,
            promptValue
        ).then(async result => {
            // TODO: remove when feature 'fwk-notif-headerAuth' rollout is complete.
            if (result) {
                const DAM = await getAnchorMailboxFromMsalAccount();
                self.document.cookie = `DefaultAnchorMailbox=${DAM}; path=/; secure; SameSite=None;`;
            }

            return result;
        });
    }

    return loginPromise;
}

async function getAuthTokenForStartupData(
    correlationId: string,
    username?: string,
    claims?: string,
    promptValue?: string
): Promise<string | undefined> {
    const msalInstance = await getMsalInstance();
    const isRunningHosted = isHosted();
    const domainTypeHint = getTypeHintFromDomain();
    logStartCoreUsage('Msal-AuthForStartupData', {
        accountType: domainTypeHint,
        isHosted: isRunningHosted,
        correlationId,
    });

    const startTime = self.performance.now();

    // Step 1: check whether it's coming back from a successful authentication redirect.
    if (!isRunningHosted) {
        try {
            const authResult = await handleLoginRedirectPromise(
                msalInstance,
                domainTypeHint,
                correlationId,
                claims
            );
            if (authResult && getTypeHintFromAccountInfo(authResult.account) === domainTypeHint) {
                logStartCoreUsage('Msal-AuthForStartupData-Latency', {
                    accountType: domainTypeHint,
                    isSuccess: true,
                    isRedirectPromise: true,
                    latency: self.performance.now() - startTime,
                    fromCache: authResult.fromCache,
                    isHosted: isRunningHosted,
                    hasClaims: !!claims,
                    correlationId,
                });
                setAuthTiming('reds');
                setActiveAccountRelatedProperties(msalInstance, authResult.account);
                return msalAuthenticationResultToAuthorizationHeader(authResult, domainTypeHint);
            }
        } catch {
            // we came back from a redirect with an auth error, we cannot do anything to recover here
            logStartCoreUsage('Msal-AuthForStartupData-Latency', {
                accountType: domainTypeHint,
                isSuccess: false,
                isRedirectPromise: true,
                latency: self.performance.now() - startTime,
                isHosted: isRunningHosted,
                hasClaims: !!claims,
                correlationId,
            });
            setAuthTiming('rede');
            return undefined;
        }
    }

    // Step 2: look for prompt query parameter and handle Interactive Login Request.
    if (!isRunningHosted && promptValue) {
        setAuthTiming('inlog');
        await loginUserMsalInternal(
            correlationId,
            msalInstance,
            InteractionType.Redirect,
            domainTypeHint,
            undefined /*msalAccount*/,
            undefined /*mailboxInfo*/,
            undefined /*username*/,
            undefined /*resource*/,
            undefined /*scope*/,
            promptValue,
            claims
        );
        return undefined;
    }

    // Step 3: none of the above, handle it as Silent Login Request.
    // Select an appropriate account to use from the MSAL cache if any are present.
    const account = selectAccount(msalInstance, domainTypeHint, username);
    const interactionType = isRunningHosted ? InteractionType.Popup : InteractionType.Redirect;
    let authResult: AuthenticationResult | null = null;
    let authResultPromise: Promise<AuthenticationResult> | null = null;
    setAuthTiming('silogs');

    if (account) {
        // MSAL account is already present: use it with acquireTokenSilent.
        authResultPromise = acquireTokenSilently(
            correlationId,
            msalInstance,
            account,
            domainTypeHint,
            undefined /*resource*/,
            undefined /*scope*/,
            claims
        );
    } else if (username) {
        // No MSAL account present but username specified: use prompt=select_account with interactive login to
        // avoid MSAL automatically picking something unexpected. Pass login_hint to prefill fields.
        authResultPromise = loginUserMsalInternal(
            correlationId,
            msalInstance,
            interactionType,
            domainTypeHint,
            undefined /*msalAccount*/,
            undefined /*mailboxInfo*/,
            username,
            undefined /*resource*/,
            undefined /*scope*/,
            PromptValue.SELECT_ACCOUNT,
            claims
        );
    } else {
        // No MSAL account or username present: attempt ssoSilent to find an active session.
        authResultPromise = loginUserMsalInternal(
            correlationId,
            msalInstance,
            InteractionType.Silent,
            domainTypeHint,
            undefined /*msalAccount*/,
            undefined /*mailboxInfo*/,
            undefined /*username*/,
            undefined /*resource*/,
            undefined /*scope*/,
            undefined /*promptValue*/,
            claims
        );
    }

    authResult = await authResultPromise
        // 1st-level error handler: handle errors from authResultPromise and fallback to interactive login if necessary
        .catch(error => {
            if (shouldPerformInteraction(error, account, username)) {
                setAuthTiming('silinlog');
                // Step 4: silent login failed, initiate interactive login for business accounts and monitoring probe.
                // Pass login_hint to prefill fields.
                return loginUserMsalInternal(
                    correlationId,
                    msalInstance,
                    interactionType,
                    domainTypeHint,
                    account,
                    undefined /*mailboxInfo*/,
                    username,
                    undefined /*resource*/,
                    undefined /*scope*/,
                    undefined /*promptValue*/,
                    claims
                );
            }

            throw error;
        })
        // 2nd-level error handler: catch-all errors from interactive login and other unhandled errors
        .catch(() => {
            if (isMsalEnabledForConsumerDomain()) {
                // If unable to fetch token for startupdata for consumers, then perform client side redirect to nlp=0 to redirect to the landing page.
                throw new Error('NeedsRedirectWithNlp');
            }

            return null;
        });

    if (authResult && getTypeHintFromAccountInfo(authResult.account) === domainTypeHint) {
        logStartCoreUsage('Msal-AuthForStartupData-Latency', {
            accountType: domainTypeHint,
            isSuccess: true,
            isAccountPresent: !!account,
            latency: self.performance.now() - startTime,
            fromCache: authResult.fromCache,
            isHosted: isRunningHosted,
            hasClaims: !!claims,
            correlationId,
        });
        setAuthTiming('silogsc');
        setActiveAccountRelatedProperties(msalInstance, authResult.account);
        return msalAuthenticationResultToAuthorizationHeader(authResult, domainTypeHint);
    }

    // Step 5: failed to either get access token or initiate interactive login, return undefined
    logStartCoreUsage('Msal-AuthForStartupData-Latency', {
        accountType: domainTypeHint,
        isSuccess: false,
        isAccountPresent: !!account,
        latency: self.performance.now() - startTime,
        isHosted: isRunningHosted,
        hasClaims: !!claims,
        correlationId,
    });

    setAuthTiming('notoken');
    return undefined;
}

function shouldPerformInteraction(
    error: AuthError,
    account?: AccountInfo,
    username?: string
): boolean {
    // only perform interaction for business accounts and monitoring probe.
    if (
        isMsalEnabledForBusiness() ||
        isMonitoringProbe() ||
        (isMsalEnabledForConsumer() && (account || username))
    ) {
        if (error instanceof InteractionRequiredAuthError) {
            return true;
        } else if (error instanceof BrowserAuthError) {
            // we seem to be getting a lot of monitor_window_timeout errors in ssoSilent,
            // workaround with redirect for now until improvements are made to reduce these.
            return error.errorCode === BrowserAuthErrorCodes.monitorWindowTimeout;
        }
    }

    return false;
}

function getLoginHint(): string | undefined {
    return getQueryStringParameters().login_hint;
}

function getPromptValue(): string | undefined {
    const prompt = getQueryStringParameters().prompt;
    switch (prompt) {
        case 'create_account':
            return PromptValue.CREATE;
        case 'select_account':
            return PromptValue.SELECT_ACCOUNT;
        case 'login':
            return PromptValue.LOGIN;
        default:
            return undefined;
    }
}

function selectAccount(
    msalInstance: IPublicClientApplication,
    domainTypeHint: AccountSourceType,
    username?: string
): AccountInfo | undefined {
    // 1. if a specific username/login_hint is given, we only return a matching account
    if (username) {
        const account = getAccountFromMsalByUsername(msalInstance, username);
        return account && getTypeHintFromAccountInfo(account) === domainTypeHint
            ? account
            : undefined;
    }

    // 2. no username present, we use the active account if one is set
    const activeAccount = getActiveAccountFromMsal(msalInstance);
    if (activeAccount && getTypeHintFromAccountInfo(activeAccount) === domainTypeHint) {
        return activeAccount;
    }

    // 3. fallback to the first available account in the cache
    // TODO: show account selection UX if there are multiple accounts
    return (
        getAllAccountFromMsal(msalInstance).find(
            account => getTypeHintFromAccountInfo(account) === domainTypeHint
        ) ?? undefined
    );
}
