import { getIndexerValueForMailboxInfo, type MailboxInfo } from 'owa-client-types';
import { getGuid } from 'owa-guid';
import { getAccountFromMsal } from './utils/MsalAccounts';
import { getMsalInstance } from './initializeMsalLibrary';
import { getTypeHintFromMailboxInfo } from './getTypeHint';
import { logStartCoreUsage } from 'owa-analytics-start';
import { getAccountScopeUserSettings } from 'owa-session-store/lib/selectors/getAccountScopeUserSettings';
import type { SilentRequest, AuthenticationResult } from '@azure/msal-browser-1p';
import { isFeatureEnabled } from 'owa-feature-flags';
import { getScopes } from './utils/getScopes';
import { getAuthority } from './utils/getAuthority';
import getSilentRedirectUri from './utils/getSilentRedirectUri';

enum State {
    Scheduled,
    Skip,
}
const BackgroundRefreshState = new Map<string, State>();

export function setupBackgroundTokenRefresh(mailboxInfo: MailboxInfo) {
    if (!isFeatureEnabled('auth-msaljs-autoRefresh')) {
        return;
    }

    const key = getIndexerValueForMailboxInfo(mailboxInfo);
    const state = BackgroundRefreshState.get(key);
    if (state != undefined) {
        return;
    }

    const policies = getAccountScopeUserSettings(mailboxInfo).PolicySettings;
    const isKmsiEnabled = policies?.SignInState?.includes('kmsi');
    if (!isKmsiEnabled) {
        BackgroundRefreshState.set(key, State.Skip);
        return;
    }

    const delayInMs = 4 * 60 * 60 * 1000; // 4 hours

    setInterval(() => {
        forceRefreshTokens(mailboxInfo);
    }, delayInMs);

    BackgroundRefreshState.set(key, State.Scheduled);
}

/**
 * Force renew Refresh Token in the background, to ensure valid tokens for longer period of time, and reduce delays for subsequent token requests.
 * Note: ssoSilent doesn't work when 3rd party cookies are blocked. In that case, renewing Refresh Token would fail silently and user session will continue using existing RT while it's valid.
 */
async function forceRefreshTokens(mailboxInfo: MailboxInfo): Promise<void> {
    const startTime = self.performance.now();

    const typeHint = getTypeHintFromMailboxInfo(mailboxInfo);
    const correlationId = getGuid();
    const msalInstance = await getMsalInstance();
    const account = getAccountFromMsal(msalInstance, mailboxInfo);
    let authResult: AuthenticationResult | null = null;
    let errorCode;
    let errorMessage;

    if (account) {
        const forceRefreshRequest: SilentRequest = {
            account,
            scopes: getScopes(typeHint),
            authority: getAuthority(typeHint),
            redirectUri: getSilentRedirectUri(),
            correlationId,
            forceRefresh: true, // Force refresh Access Token
            refreshTokenExpirationOffsetSeconds: 72000, // Force renew Refresh Token if it expires in 20 hrs
        };

        authResult = await msalInstance.acquireTokenSilent(forceRefreshRequest).catch(error => {
            errorCode = error.errorCode;
            errorMessage = error.errorMessage;
            return null;
        });
    }

    logStartCoreUsage('Msal-BackgroundRefresh', {
        correlationId,
        accountType: typeHint,
        isAccountPresent: !!account,
        isSuccess: !!authResult,
        errorCode,
        errorMessage,
        latency: self.performance.now() - startTime,
        pageVisible: self.document.visibilityState === 'visible',
    });
}
