import type { PopupRequest, PublicClientApplication } from '@azure/msal-browser-1p';
import { InteractionRequiredAuthError } from '@azure/msal-browser-1p';
import type MsalTokenCallback from './MsalTokenCallback';
import acquireTokenMsal from './acquireTokenMsal';
import { getScopeMsal } from './getScopeMsal';

export default function loginUserAndAcquireTokenMsal(
    msalInstance: PublicClientApplication,
    resource: string,
    authChallenge: string,
    allowConsentPrompt: boolean,
    userPrincipalName: string | undefined,
    getAccessTokenCallback: MsalTokenCallback
): void {
    const scopes = [getScopeMsal(resource)];
    const loginRequest: PopupRequest = {
        scopes,
        loginHint: userPrincipalName,
        claims: authChallenge,
    };

    msalInstance
        .ssoSilent(loginRequest)
        .catch(function (error) {
            if (allowConsentPrompt && error instanceof InteractionRequiredAuthError) {
                return msalInstance.loginPopup(loginRequest);
            }

            return Promise.reject(error);
        })
        .then(function (loginResponse) {
            acquireTokenMsal(
                msalInstance,
                resource,
                authChallenge,
                allowConsentPrompt,
                loginResponse.account,
                getAccessTokenCallback
            );
        })
        .catch(function (error) {
            getAccessTokenCallback(error, null /* token */);
        });
}
