import { LazyModule, LazyAction } from 'owa-bundling-light';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "NestedAppAuth" */ './lazyIndex')
);

export const lazyExecuteNaaRequestForMailboxInfo = new LazyAction(
    lazyModule,
    m => m.executeNaaRequestForMailboxInfo
);

export type { NestedAppAuthExecutorFactory } from './NestedAppAuthExecutorFactory';
export type { NestedAppAuthExecutor } from './NestedAppAuthExecutor';

// These will need to eventually come from msal-browser
export type { AccountInfo } from './msal-types/AccountInfo';
export type { AuthResult } from './msal-types/AuthResult';
export type { BridgeError } from './msal-types/BridgeError';
export type { InitContext } from './msal-types/InitContext';
export type { TokenRequest } from './msal-types/TokenRequest';
export type { TokenResponse } from './msal-types/TokenResponse';
