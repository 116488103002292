import { isFeatureEnabled } from 'owa-feature-flags';
import { createMsalInstance, getMsalInstance } from './initializeMsalLibrary';
import { getAccountFromMsal, getActiveAccountFromMsal } from './utils/MsalAccounts';
import { trace } from 'owa-trace';
import getModuleContextMailboxInfo from 'owa-module-context-mailboxinfo/lib/selectors/getModuleContextMailboxInfo';
import { removeIsAuthenticated } from './isAnonymousSession';
import { setOwaAppId } from 'owa-config/lib/getOwaAppId';
import type { IPublicClientApplication } from '@azure/msal-browser-1p';
import { getPostLogoutRedirectUri } from './utils/getPostLogoutRedirectUri';
import { clearCookies } from './utils/clearCookies';
import isAccountSourceListStoreInitialized from 'owa-account-source-list-store/lib/utils/isAccountSourceListStoreInitialized';

export default async function signoutFromMsal(
    skipFlightCheck?: boolean,
    isSharedABT?: boolean
): Promise<void> {
    let msalInstance: IPublicClientApplication | null = null;

    if (!!skipFlightCheck || isFeatureEnabled('auth-msaljs-clearMsalArtifactsOnLogoff')) {
        try {
            // signout should work even if MSAL hasn't been used during the session
            createMsalInstance();
        } catch {
            // noop - error expected here if MSAL already initialized
        }

        try {
            msalInstance = await getMsalInstance();
        } catch (e) {
            trace.info('MsalInitFailure-Logout ' + { Error: e });
        }
    }

    if (msalInstance) {
        let userAccount;
        if (isAccountSourceListStoreInitialized()) {
            userAccount = getAccountFromMsal(msalInstance, getModuleContextMailboxInfo());
        } else {
            userAccount = getActiveAccountFromMsal(msalInstance);
        }
        if (userAccount != null) {
            setOwaAppId(null);
            removeIsAuthenticated();
            await clearCookies(userAccount.tenantId);
            const logoutRequest = {
                account: userAccount,
                postLogoutRedirectUri: getPostLogoutRedirectUri(userAccount, isSharedABT),
            };
            await msalInstance.logoutRedirect(logoutRequest);
        }
    }
}
