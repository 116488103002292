import type { MailboxInfo } from 'owa-client-types';
import {
    lazyExecuteNaaRequestForMailboxInfo,
    type NestedAppAuthExecutorFactory,
} from 'owa-nested-app-auth';
import { MsalNestedAppAuthExecutor } from './MsalNestedAppAuthExecutor';

const executorFactory: NestedAppAuthExecutorFactory = mailboxInfo =>
    new MsalNestedAppAuthExecutor(mailboxInfo);

export default function executeMsalNestedAppAuthRequest(
    mailboxInfo: MailboxInfo,
    request: string,
    origin: string,
    scenarioName: string,
    callerId: string
): Promise<string> {
    return lazyExecuteNaaRequestForMailboxInfo.importAndExecute(
        executorFactory,
        mailboxInfo,
        request,
        origin,
        scenarioName,
        callerId
    );
}
