import type { MailboxInfo } from 'owa-client-types';
import { getAuthenticationUrl } from 'owa-tokenprovider-utils/lib/getAuthenticationUrl';
import { getAccountScopeUserSettings } from 'owa-session-store/lib/selectors/getAccountScopeUserSettings';
import type MsalTokenCallback from './MsalTokenCallback';
import createMsalPublicClientApplication from './createMsalPublicClientApplication';
import acquireTokenMsal from './acquireTokenMsal';
import loginUserAndAcquireTokenMsal from './loginUserAndAcquireTokenMsal';

export async function getAddinSsoTokenMsal(
    clientId: string,
    redirectUri: string,
    resource: string,
    authChallenge: string,
    allowConsentPrompt: boolean,
    getAccessTokenCallback: MsalTokenCallback,
    mailboxInfo: MailboxInfo
): Promise<void> {
    let authUrl = await getAuthenticationUrl(mailboxInfo);
    // We support only Azure AD accounts for now in getAccessTokenAsync.
    // Hence, restricting authURL by adding 'organizations'.
    authUrl += 'organizations';

    const msalInstance = await createMsalPublicClientApplication(authUrl, clientId, redirectUri);

    const sessionSettings = getAccountScopeUserSettings(mailboxInfo).SessionSettings;
    const userPrincipalName = sessionSettings?.IsExplicitLogon
        ? sessionSettings?.LogonEmailAddress
        : sessionSettings?.UserPrincipalName;

    const userAccount = userPrincipalName
        ? msalInstance.getAccount({ username: userPrincipalName })
        : null;

    if (userAccount) {
        acquireTokenMsal(
            msalInstance,
            resource,
            authChallenge,
            allowConsentPrompt,
            userAccount,
            getAccessTokenCallback
        );
    } else {
        loginUserAndAcquireTokenMsal(
            msalInstance,
            resource,
            authChallenge,
            allowConsentPrompt,
            userPrincipalName,
            getAccessTokenCallback
        );
    }
}
